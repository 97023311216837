var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":require('@/assets/images/logo/512.svg'),"width":"240","height":"240"}})],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Welcome to FastProxy.vip ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Residential Socks optimal and performance ")]),_c('b-tabs',[_c('b-tab',{attrs:{"title":"Login as Admin"}},[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"vue_1",staticClass:"auth-login-form mt-2",attrs:{"method":"post"},on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label-for":"Email or Username","label":"Email or Username"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","name":"login-user","state":errors.length > 0 ? false:null,"placeholder":"Email","autofocus":""},on:{"change":function($event){_vm.show_otp = false}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{name:'forgot-password'}}},[_c('small',[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.show_otp)?_c('b-form-group',{attrs:{"label-for":"OTP","label":"OTP"}},[_c('validation-provider',{attrs:{"name":"OTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"otp_","name":"otp-user","state":errors.length > 0 ? false:null,"placeholder":"OTP","autofocus":""},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"checkbox-1"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v(" Remember Me ")])],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[(_vm.processing)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Sign in ")],1)],1)]}}])})],1),_c('b-tab',{attrs:{"title":"Login as Member"}},[_c('validation-observer',{ref:"loginMForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"vue_1",staticClass:"auth-login-form mt-2",attrs:{"method":"post"},on:{"submit":_vm.onMemberSubmit}},[_c('b-form-group',{attrs:{"label-for":"Email or Username","label":"Email or Username"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"keyport","name":"login-user","state":errors.length > 0 ? false:null,"placeholder":"Key Port","autofocus":""},model:{value:(_vm.kuser),callback:function ($$v) {_vm.kuser=$$v},expression:"kuser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"login-password","placeholder":"Password"},model:{value:(_vm.kpassword),callback:function ($$v) {_vm.kpassword=$$v},expression:"kpassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"checkbox-1"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v(" Remember Me ")])],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[(_vm.processing)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Sign in ")],1)],1)]}}])})],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('div',[_c('b-link',{attrs:{"to":{name:'register'}}},[_c('span',[_vm._v("Create an account?")])])],1)]),[_c('div',[_c('b-toast',{attrs:{"id":"alert_toast","variant":_vm.toastConfig.variant,"solid":""},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('feather-icon',{attrs:{"icon":_vm.toastConfig.icon}}),_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('strong',{staticClass:"mr-auto"},[_vm._v(" "+_vm._s(_vm.toastConfig.title)+"!")])])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.toastConfig.message)+" ")])],1)]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }